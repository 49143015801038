import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/'
import { 
    InputField
} from '../../components/library/entitysync'; 


function inputTextarea() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <InputField 
                    field="input_text_basic" 
                    label="Default"
                    multiline
                />
                <hr />
                <InputField
                    field="input_error"
                    label="With Error"
                    helperText="Something wrong"
                    multiline
                    error
                />
            </Entity>
        </LibraryWrapper>
    )
}
export default inputTextarea
